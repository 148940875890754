.stat-title {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.95) !important;
    margin-top: 1 !important;
    margin-bottom: 0.0em !important;
    margin-top: 0.5em;
    font-family: sans-serif;
    text-align: center;
  }
  
.bdr-none .ant-select-selection
{
    border: 0px !important;
}
.ant-select-selection__placeholder{
  padding-top: 3px;
}
  
  .countdown {
    margin: 0 auto; 
    width: fit-content; 
    display: flex;
    gap: 30px;
    font-family: sans-serif;
    text-align: center;
    margin-bottom: 2.5rem;

  }
  
  .time-section {
    text-align: center;
    font-size: 30px;
   
  }


.time-group .digit {
  color: black; 
  font-size: 2rem;
}


.time-group .comma {
  color: white; 
  font-size: 2rem;
  margin: 0 2px; 
}

  
  .time-group {
    display: flex;
    gap: 10px;
  }
  
  .time-segment {
    display: block;
    font-size: 35px;
    font-weight: 900;
    width: 50px;
  }
  
  .segment-display {
    position: relative;
    height: 100%;
  }
  
  .segment-display__top,
  .segment-display__bottom {
    overflow: hidden;
    text-align: center;
    width: 100%;
    height: 50%;
    position: relative;
  }
  
  .segment-display__top {
    line-height: 1.5;
    color: #000;
    background-color: #fff;
  }
  
  .segment-display__bottom {
    line-height: 0;
      color: #000;
      background-color: #fff;
  }
  
  .segment-overlay {
    position: absolute;
    top: 0;
    perspective: 400px;
    height: 100%;
    width: 50px;
  }
  
  .segment-overlay__top,
  .segment-overlay__bottom {
    position: absolute;
    overflow: hidden;
    text-align: center;
    width: 100%;
    height: 50%;
  }
  
  .segment-overlay__top {
    top: 0;
        line-height: 1.5;
        color: #000;
        background-color: #fff;
        transform-origin: bottom;
  }
  
  .segment-overlay__bottom {
    bottom: 0;
          line-height: 0;
          color: #000;
          background-color: #fff;
          border-top: 0.1px solid rgb(98, 98, 98);
          transform-origin: top;
  }
  
  .segment-overlay.flip .segment-overlay__top {
    animation: flip-top 0.8s linear;
  }
  
  .segment-overlay.flip .segment-overlay__bottom {
    animation: flip-bottom 0.8s linear;
  }
  
  @keyframes flip-top {
    0% {
      transform: rotateX(0deg);
    }
    50%,
    100% {
      transform: rotateX(-90deg);
    }
  }
  
  @keyframes flip-bottom {
    0%,
    50% {
      transform: rotateX(90deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }

  @media (max-width: 1300px) {
    .stat-title {
      font-size: 18px;
    }
  }
  
